import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { SEO } from '../components';
import { SEO_TEXT } from '../constants';
import { SanityServiceType } from '../utils/globalTypes';
import { ServiceFirstSection, ServiceSecondSection, ServiceThirdSection } from '../components';
import { getClient } from '../utils/sanity.client';
import { serviceQuery } from '../utils/querys/service/query';

interface Props {
  data: {
    sanityService: SanityServiceType;
  };
}

const Services = ({ data: { sanityService } }: Props) => {
  const [serviceData, setServiceData] = useState<any>();

  useEffect(() => {
    const url = new URL(location.href);
    const getData = async () => {
      const client = getClient({ token: process.env.GATSBY_SANITY_READ_TOKEN || '' });
      const data = await client.fetch(serviceQuery);
      setServiceData(data);
    };
    if (serviceData === undefined && url.search === '?preview_mode=true') getData();
  }, [serviceData]);
  return (
    <Fragment>
      <SEO title={sanityService.seo?.title || ''} description={sanityService.seo?.description || ''} />
      <ServiceFirstSection content={serviceData ? serviceData.firstSection : sanityService.firstSection} />
      <ServiceSecondSection content={serviceData ? serviceData.secondSection : sanityService.secondSection} />
      <ServiceThirdSection content={serviceData ? serviceData.thirdSection : sanityService.thirdSection} />
    </Fragment>
  );
};

export default Services;

export const pageQuery = graphql`
  query {
    sanityService {
      seo {
        title
        keywords
        description
      }
      firstSection {
        title
        serviceImage {
          asset {
            gatsbyImageData(fit: SCALE)
          }
        }
      }
      secondSection {
        title
        description
        services {
          title
          _rawDescription
        }
      }
      thirdSection {
        buttonText
        subTitle
        title
      }
    }
  }
`;
